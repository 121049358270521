import React from 'react';

// CSS
import './css/Exp.css'

const ExpCon=(props)=> {
    return(
        <div className="position-relative // m-2">
            <div className='row'>

                <div className='spring_5 btn rounded-3 d-flex align-items-center justify-content-center text-light // col-lg-1 col-md-2 col-sm-12' 
                data-bs-toggle="collapse" 
                data-bs-target={"#" + props.id} 
                aria-expanded="false" 
                aria-controls={props.id} 
                style={{ backgroundImage: 'linear-gradient(to top right, #00b300, #003380)' }}>
                    {props.name}
                </div>

                <div className="collapse col-lg-8 col-md-6 col-sm-12 mt-1" id={props.id}>
                    <div class="card card-body rounded-4 text-light" style={{ backgroundColor: 'rgba(50, 50, 50, 0.9)'}}>
                        {props.text}
                    </div>
                </div>

                <div className="collapse // col-lg-3 col-md-4 col-sm-12 mt-1" id={props.id}>
                    <div className='d-flex align-items-center justify-content-center'>
                        <a className='#' href={props.href} target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}><img src={props.img} className='exp-pic w-90 h-90 rounded-4' alt="#" /></a>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default ExpCon;
import React from "react"

// CSS
import './css/Contact.css'

// Images
//import Pic_I from './images/night.gif';

const ContactForm=()=> {
    return(
        <div className='rounded-4 // p-2' style={{ backgroundColor: 'rgba(26,26,26, 0.85)' }}>
            <div className='d-flex align-items-center justify-content-center // text-warning'>
                <h1 className='text-head'>OTHER CONTACTS</h1>
            </div>

            <div className='container // text-white mt-3'>
                <div className='row'>
                    <div className='col-3'>
                        <p>Call: </p>
                    </div>
                    <div className='col-8'>
                        <p>*** The information is hidden ***</p>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-3'>
                        <p>Address: </p>
                    </div>
                    <div className='col-8'>
                        <p>Adelaide, SA Australia</p>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-3'>
                        <p>Email: </p>
                    </div>
                    <div className='col-8'>
                        <p>tanapong11027@gmail.com</p>
                    </div>
                </div>

                <div className='row mt-3'>
                    <div className='contact-spring col-lg-3 col-md-4 col-sm-6'>
                        <a className='contact-spring text-light' href="https://www.linkedin.com/in/tanapongdech" target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}><h3 className="#"><i className="fa-brands fa-linkedin"/><h6 className="d-inline"> LinkedIn</h6></h3></a>
                    </div>
                    <div className='contact-spring col-lg-3 col-md-4 col-sm-6'>
                        <a className='text-light' href="https://www.facebook.com/tanapongdech" target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}><h3 className="#"><i className="fa-brands fa-facebook"/><h6 className="d-inline"> &nbsp;Facebook</h6></h3></a>
                    </div>
                    <div className='contact-spring col-lg-3 col-md-4 col-sm-6'>
                        <a className='text-light' href="https://www.instagram.com/tanapongdech/" target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}><h3 className="#"><i className="fa-brands fa-instagram"/><h6 className="d-inline"> &nbsp;Instagram</h6></h3></a>
                    </div>
                    <div className='contact-spring col-lg-3 col-md-4 col-sm-6'>
                        <a className='text-light' href="https://github.com/TanapongAUS" target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}><h3 className="#"><i className="fa-brands fa-github"></i><h6 className="d-inline"> &nbsp;Github</h6></h3></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactForm;

// backgroundImage: `url(${Pic_I})`
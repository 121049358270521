import React from 'react';
import Navbar from '../../components/navbar/Navbar';

// Components
import ContactForm from '../../components/contact/ContactForm';
import OtherContact from '../../components/contact/OtherContact';

// Images
import Background from './images/background.gif'; 


function Contact() {
    return(
        <div style={{backgroundImage: `url(${Background})`, backgroundColor: 'rgba(3, 10, 16, 1)', backgroundSize: 'cover', minHeight: '100vh', maxHeight: '100%'}}>
            <Navbar />

            <div className='row // ms-lg-5 ms-md-3 ms-1 me-lg-5 me-md-3 me-1 mt-3 mb-3'>
                <div className='col-lg-6 col-md-12 col-sm-12 // '>
                    <ContactForm  />
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12 // mt-4 mt-lg-0 mt-md-4 mt-sm-4 mb-4 mb-lg-0 mb-md-4 mb-sm-4'>
                    <OtherContact />
                </div>
            </div>
        </div>
    )
}

export default Contact;
import React from 'react';
import {Link} from 'react-router-dom';

// CSS
import './css/Navbar.css';

function Navbar() {
    return(
        <nav className="navbar navbar-expand-md rounded-bottom-5 // m-0">
            <Link to='/' className="nav-logo // fs-4 ms-4">TDech Portfolio.</Link>
            
            <button
                className="toggler-hover navbar-toggler // me-4"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse justify-content-md-end" id="navbarCollapse">
                <ul className="navbar-nav">
                    <Link to="/" className="nav-hover nav-item btn rounded-pill // me-3 m-1">Home</Link>
                    <Link to="/about" className="nav-hover nav-item btn rounded-pill // me-3 m-1">About Me</Link>
                    <Link to="/portfolio" className="nav-hover nav-item btn rounded-pill // me-3 m-1">Portfolio</Link>
                    <Link to="/weather" className="nav-hover nav-item btn rounded-pill // me-3 m-1">Weather App</Link>
                    <Link to="/contact" className="nav-hover nav-item btn rounded-pill // me-3 m-1">Contact</Link>
                </ul>
            </div>
        </nav>
    )
}

export default Navbar;
import React from "react";
import {Link} from 'react-router-dom';

import './css/FollowMe.css'

const FollowMe=()=> {
    return(
        <div>
            <div className='position-relative // rounded-4 m-3 mt-4 p-3' style={{ backgroundColor: 'rgba(32,32,32, 0.8)' }}>
                <div className='d-flex align-items-center justify-content-center text-info'>
                    <h3 className='text-head'>FOLLOW ME</h3>
                </div>
                
                <div className='container mt-3'>
                    <div className='row'>
                        <strong className='hover col-lg-2 col-md-4'>
                            <a className='text-light' href="https://www.linkedin.com/in/tanapongdech" target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}><h2 className="spring"><i className="fa-brands fa-linkedin"/><h5 className="d-inline"> &nbsp;LinkedIn</h5></h2></a>
                        </strong>
                        <strong className='hover col-lg-2 col-md-4'>
                            <a className='text-light' href="https://www.facebook.com/tanapongdech" target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}><h2 className="spring"><i className="fa-brands fa-facebook"/><h5 className="d-inline"> &nbsp;Facebook</h5></h2></a>
                        </strong>
                        <strong className='hover col-lg-2 col-md-4'>
                        <a className='text-light' href="https://www.instagram.com/tanapongdech/" target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}><h2 className="spring"><i className="fa-brands fa-instagram"/><h5 className="d-inline"> &nbsp;Instagram</h5></h2></a>
                        </strong>
                        <strong className='hover col-lg-2 col-md-4'>
                            <a className='text-light' href="https://github.com/TanapongAUS" target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}><h2 className="spring"><i className="fa-brands fa-github"></i><h5 className="d-inline"> &nbsp;Github</h5></h2></a>
                        </strong>
                        <strong className='hover col-lg-2 col-md-4'>
                            <Link className='text-light' to='/contact' style={{textDecoration: 'none'}}><h2 className="spring"><i class="fa-brands fa-rocketchat"/><h5 className="d-inline"> &nbsp;Contact</h5></h2></Link>  
                        </strong>
                    </div>
                </div>
            </div>
            
            <div className='d-flex align-items-center justify-content-center text-white mt-3 mb-0'>
                <p>TDech Copyright©2023</p>
            </div>
        </div>
    )
}

export default FollowMe;

//d-flex align-items-center justify-content-center
import React, { useState} from 'react';

// Css
import './css/Contact.css'

const ContactForm=()=> {
    //Initiate of useEffect
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    //Changing map from specified address
    const handleInput_1 = (event) => {setName(event.target.value);};
    const handleInput_2 = (event) => {setPhone(event.target.value);};
    const handleInput_3 = (event) => {setEmail(event.target.value);};
    const handleInput_4 = (event) => {setMessage(event.target.value);};
    //==========+++++==========

    const submit = () => {
        // Check for correctness
        const phoneRegex = /^\d+$/;

        if (name === '') {alert('Error: Please provide us the name');}
        else if(phone!=='' && !phoneRegex.test(phone)) {alert('Error: Please enter a valid phone number with digits only');}
        else if(email!=='' && !email.includes('@')) {alert('Error: Please enter a valid email address with "@" sign');}
        else if(message === '') {alert('Error: Please provide us the message');}
        else {
            // Clear all inputs
            setName('');
            setPhone('');
            setEmail('');
            setMessage('');

            // Show alert
            alert('Thank you! We have received your message');
        }
    }

    return(
        <div className='rounded-4 // p-2' style={{ backgroundColor: 'rgba(26,26,26, 0.85)' }}>
            <div className='d-flex align-items-center justify-content-center // text-warning'>
                <h1 className='text-head'>CONTACT INBOX</h1>
            </div>

            <div class="m-3">
                <label for="Input_1" className="form-label // text-white">Contact Name:</label>
                <input type="email" className="form-control" id="Input_1" placeholder="Name" value={name} onChange={handleInput_1} 
                style={{ backgroundColor: 'rgb(216, 216, 216)', border: 'none'}}/>
            </div>
            <div class="m-3">
                <label for="Input_2" className="form-label // text-white">Contact Mobile:</label>
                <input type="email" className="form-control" id="Input_2" placeholder="043xxxxxxx" value={phone} onChange={handleInput_2} 
                style={{ backgroundColor: 'rgb(216, 216, 216)', border: 'none'}}/>
            </div>
            <div class="m-3">
                <label for="Input_3" className="form-label // text-white">Contact Email:</label>
                <input type="email" className="form-control" id="Input_3" placeholder="email@example.com" value={email} onChange={handleInput_3}
                style={{ backgroundColor: 'rgb(216, 216, 216)', border: 'none'}}/>
            </div>
            <div class="m-3">
                <label for="Input_4" className="form-label // text-white">Message:</label>
                <textarea className="form-control" id="Input_4" rows="3" placeholder="Please enter your message here" value={message} onChange={handleInput_4}
                style={{ backgroundColor: 'rgb(216, 216, 216)', border: 'none'}}/>
            </div>
            <div class="d-flex align-items-center justify-content-end // m-3 mb-2">
                <button className="submit btn rounded-pill d-inline-flex text-light" onClick={submit}>Submit</button>
            </div>
        </div>
    )
}

export default ContactForm;
import React from 'react';

// Components
import SkillCard from './SkillCard';

// Images
import PythonImg from './images/Python-logo.jpg';
import ReactImg from './images/React-Logo.jpg';
import HtmlImg from './images/html-logo.jpg';
import JavaImg from './images/java-logo.jpg';
import CssImg from './images/css-logo.jpg';
import JsImg from './images/JavaScript-Logo.jpg';
import SqlImg from './images/sql-logo.jpg';
import Mql5Img from './images/mql5-logo.jpg';

const Skill=()=> {
    return(
        <div className='position-relative // rounded-4 m-3 mt-4 pt-3 p-2' style={{ backgroundColor: 'rgba(32,32,32, 0.8)' }}>
            <div className='d-flex align-items-center justify-content-center text-warning'>
                <h2 className='text-head '>SKILLS</h2>
            </div>
            
          
                <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-12 // mt-2'>
                        <SkillCard name="PYTHON" img={PythonImg} id="1" level="65" 
                        text={["Python for Data Sciene", 
                            <br/>,"Program:"," Anaconda & VScode (.npynb & .py)", 
                            <br/>,"Using library:", 
                            <br/>,"Dataframe - Pandas, Numpy",
                            <br/>,"Visualization - MatPlotlib, Seaborn",
                            <br/>,"Machine Learning - Deep Learning (RL)-Pytorch, Tensorflow2-Keras, SKlearn",
                            <br/>,"others - OS, datetime, deque, socket" ]}
                        />
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 // mt-2'>
                        <SkillCard name="JAVA" img={JavaImg} id="2" level="50"
                        text={["JAVA for OOP (Object Oriented Program)",
                            <br/>,"Program:"," IDE & VScode (.java)", 
                            <br/>,"- Encapsulation", 
                            <br/>,"- Abstraction",
                            <br/>,"- Inheritance",
                            <br/>,"- Polymorphism"]}
                        />
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 // mt-2'>
                        <SkillCard name="HTML" img={HtmlImg} id="3" level="45" 
                        text={["Basic HTML", 
                            <br/>,"Program:"," Atom & VScode (.html)", 
                            <br/>,"- Import and export", 
                            <br/>,"- Tag",
                            <br/>,"- Creating Favicon",
                            <br/>,"- Link root to Javascript file"]}
                        />
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 // mt-2'>
                        <SkillCard name="CSS" img={CssImg} id="4" level="35" 
                        text={["Basic CSS", 
                            <br/>,"Program:"," VScode (.css)", 
                            <br/>,"- Import and export var", 
                            <br/>,"- Margin, Padding",
                            <br/>,"- Color, border and other components",
                            <br/>,"- Basic animations",
                            <br/>,"- Bootstrap5, responsive design"]}
                        />
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 // mt-2'>
                        <SkillCard name="JAVASCRIPT" img={JsImg} id="5" level="60" 
                        text={["Basic Javascript and Javascript for React", 
                            <br/>,"Program:"," VScode (.js)", 
                            <br/>,"- Import and export Module", 
                            <br/>,"- Accept event such as eventListener()"]}
                        />
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 // mt-2'>
                        <SkillCard name="SQL" img={SqlImg} id="6" level="50"
                        text={["Basic SQL", 
                            <br/>,"Program:"," SQLite & MySQL (.db)", 
                            <br/>,"- Creating databases & tables", 
                            <br/>,"- Apply Basic syntaxs",
                            <br/>,"- Other logical syntaxs such as GROUP BY, WHEN, Outer Joint"]}
                        />
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 // mt-2'>
                        <SkillCard name="REACT" img={ReactImg} id="7" level="60" 
                        text={["React for Web components - MERN Stack", 
                            <br/>,"Program:"," VScode with React and Veit framework", 
                            <br/>,"- Import and export modules",
                            <br/>,"- Creating function component both for client and server side", 
                            <br/>,"- Arrow function",
                            <br/>,"- Intergrated with Html, Css and JS",
                            <br/>,"- Other libraries: Nodes, Express, Mongo DB"]}
                        />
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 // mt-2'>
                        <SkillCard name="MQL 5" img={Mql5Img} id="8" level="65" 
                        text={["Metatrader 4&5 Algorithmics Trading", 
                            <br/>,"- Creating class",
                            <br/>,"- Basic, intermediate level for logical trading concept", 
                            <br/>,"- Open & Close Order, TP & TPSL",
                            <br/>,"- Creating and using libraries and Indicators",
                            <br/>,"- Import, export and sending signal through socket to integrate with Python Deep learning model"]}
                        />
                    </div>
                </div>
          
        </div>
    )
}

export default Skill;
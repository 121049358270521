import React from 'react';

// Components
import Navbar from '../../components/navbar/Navbar';
import FollowMe from '../../components/followMe/FollowMe';
import Exp from '../../components/experience/Experience';
import Skill from '../../components/skills/Skill';
import Award from '../../components/award/AllAward';
import Particle from '../../components/particle/Particle';


function Portfolio() {
    return(
        <div style={{ backgroundColor: 'rgba(18, 16, 39, 1)', backgroundSize: 'cover', height: '100%'}}>
            <Particle/>
            <Navbar />
            <Exp />
            <Skill />
            <Award />
            <FollowMe />
        </div>
    )
}

export default Portfolio;

// rgba(3, 10, 16, 1)
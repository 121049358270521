import React from 'react';

// CSS
import './css/Skill.css'


const SkillCard=(props)=> {
    return(
        <div className="spring_15 rounded-4 // p-1 m-1" style={{ backgroundImage: 'linear-gradient(to top right, #043F81, #2A0D3E)' }}>
            <div className='row d-flex align-items-center justify-content-center' data-bs-toggle="collapse" href={"#" + props.id} role="button" aria-expanded="false" aria-controls={props.id}>
                <div className='col-4'>
                    <img src={props.img} className='w-100 h-100 rounded-4' alt="#" />
                </div>
                <div className='col-8'>
                    <h2 className="d-flex align-items-center justify-content-center text-light">{props.name}</h2>
                </div>
            </div>

            <div className="collapse" id={props.id}>
                <div class="card card-body rounded-4 // mt-1 p-0" style={{ backgroundColor: 'rgba(230, 230, 230, 1)'}}>
                    <p className='m-0 p-1'>{props.text}</p>
                </div>
            </div>
        </div>
    )
}

export default SkillCard;

//<div className='col-4'>
//<div className="progress bg-dark">
//    <div className="progress-bar" role="progressbar" style={{width: props.level}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{props.level}</div>
//</div>
//</div>

//<div className='col-4'>
//<svg className='#' width={'100'} height={'100'} viewBox={'0 0 100 100'}>
//<circle className="circle-background" cx='50' cy='50' r='48' />
//</svg>
//</div>

//<div className='col-4 position-relative'>
//<h6 className="position-absolute">{props.level}</h6>
//<div className='progress-skill rounded-circle' style={{height:'100px', width:'100px'}} />
//</div>
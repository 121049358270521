import React from "react";

// Components
import Certificate from './Certificates';
import Project from './Projects';

// Css
import './css/AllAward.css';

// Images
import Walmart from './images/walmart.jpg';
import Google from './images/google.jpg';
import Project_1 from './images/project_1.jpg';
import Project_2 from './images/project_2.png';

// Files
import Google_Data from './files/Google_Data_Analytics_Cer.pdf';
import Forage_Walmart from './files/Walmart_cer.pdf';


const AllAward=()=> {

    return(
        <div className='position-relative rounded-4 m-3 mt-4 pt-2' style={{ backgroundColor: 'rgba(32,32,32, 0.8)' }}>
            <div className='d-flex align-items-center justify-content-center // text-warning'>
                <h2 className='text-head'>CERTIFICATES</h2>
            </div>

            <div className='row d-flex align-items-center justify-content-center // m-2'>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <Certificate img={Walmart}
                    href={Google_Data}
                    file="Google Data Analytics Cer.pdf"
                    name='Advanced Software Engineering'/>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <Certificate img={Google} 
                    href={Forage_Walmart}
                    file="Forage Walmart Cer.pdf"
                    name='Google Data Analytics'/>
                </div>
            </div>

            <div className='d-flex align-items-center justify-content-center // text-warning mt-3'>
                <h2 className='text-head'>MY PROJECTS</h2>
            </div>    

            <div className='row d-flex align-items-center justify-content-center // m-2'>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <Project img={Project_1} name='Python & Mql5 Intergration for Financial Trading' 
                    text={["Sending data signal from MQL5 to Python via socket.", 
                        <br/>,"- Real-time data analising"]}
                    />
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <Project img={Project_2} name='React Website Project' 
                    text={["My own website portfolio", 
                        <br/>,"- The collection of all my experience"]}
                    />
                </div>
            </div>
        </div>
    )
}

export default AllAward;
import React from "react"

// Components
import ProgressBar from './Reuse_Progress';
import Carousel from './Carousel';

// Css
import './css/Bio.css';

// Images
import myPic from './images/bio-pic.jpg'

const Bio=()=> {
    return(
        <div className='position-relative // rounded-4 m-3 pt-3' style={{ backgroundColor: 'rgba(32,32,32, 0.8)' }}>
            <div className='d-flex align-items-center justify-content-center // text-warning'>
                <h2 className='text-head'>BIOGRAPHY</h2>
            </div>

            <div className='row m-3'>
                <div className='col-lg-6 col-md-6 col-sm-12 rounded-4 // text-white p-3' style={{ backgroundColor: 'rgba(52, 52, 52, 1)'}}>
                    <div className="row // m-0 p-0">
                        <div className="col-4">
                            Name:
                        </div>
                        <div className="col-8">
                            Tanapong Dechsakul
                        </div>
                    </div>
                    <hr className="border-light border-3 // my-2" />
                    <div className="row // m-0 p-0">
                        <div className="col-4">
                            Year of birth:
                        </div>
                        <div className="col-8">
                            1992
                        </div>
                    </div>
                    <hr className="border-light border-3 // my-2" />
                    <div className="row // m-0 p-0">
                        <div className="col-4">
                            Favorite food:
                        </div>
                        <div className="col-8">
                            Japanese food is the best, I like Sushi
                        </div>
                    </div>
                    <hr className="border-light border-3 // my-2" />
                    <div className="row // m-0 p-0">
                        <div className="col-4">
                            Favorite color:
                        </div>
                        <div className="col-8">
                            Gold and Balck
                        </div>
                    </div>
                    <hr className="border-light border-3 // my-2" />
                    <div className="row // m-0 p-0">
                        <div className="col-4">
                            Hobby:
                        </div>
                        <div className="col-8">
                            Code the trading algorithms, watch movie, travel around and enjoy my life
                        </div>
                    </div>
                    <hr className="border-light border-3 // my-2" />
                    <div className="row // m-0 p-0">
                        <div className="col-4">
                            Language:
                        </div>
                        <div className="col-5 d-flex align-items-center">
                            Thai-Native Speaker
                        </div>
                        <div className="col-3 d-flex align-items-center justify-content-center">
                            <ProgressBar level="90" ground='#' text='90%' gradientId='gradient_I' color_I='#00D300' color_II='#18034A'/>
                        </div> {/*} color='rgb(0, 255, 225)' {*/}
                    </div>
                    <div className="row // m-0 p-0 mt-3">
                        <div className="col-4" />
                        <div className="col-5 d-flex align-items-center">
                            English-Ielt Academic 6.5
                        </div>
                        <div className="col-3 d-flex align-items-center justify-content-center">
                            <ProgressBar level="72.22" ground='#' text='72%' gradientId='gradient_II' color_I='#00D300' color_II='#18034A'/>
                        </div> {/*} color='rgb(0, 255, 225)' {*/}
                    </div>
                    <hr className="border-light border-3 // my-2" />
                    <div className="row // m-0 p-0">
                        Rating myself:
                    </div>
                    <div className="row // mt-2 m-0 p-0">
                        <div className="col-4 d-flex justify-content-center border-end border-3 border-light">
                            Energetic
                        </div>
                        <div className="col-4 d-flex justify-content-center border-end border-3 border-light">
                            Motivation
                        </div>
                        <div className="col-4 d-flex justify-content-center">
                            Inspiration
                        </div>
                    </div>
                    <div className="row // m-0 p-0">
                        <div className="col-4 d-flex justify-content-center border-end border-3 border-light // pt-3">
                            <ProgressBar ground='#' level="65" text='65%' gradientId='gradient_III' color_I='#E41313' color_II='#5B0C80'/>
                        </div> {/*} color='#F10000' {*/}
                        <div className="col-4 d-flex justify-content-center border-end border-3 border-light // pt-3">
                            <ProgressBar ground='#' level="75" text='75%' gradientId='gradient_IV' color_I='#00ECD3' color_II='#050582'/>
                        </div> {/*} color='#0020E9' {*/}
                        <div className="col-4 d-flex justify-content-center // pt-3">
                            <ProgressBar ground='#' level="60" text='60%' gradientId='gradient_V' color_I='#A600FF' color_II='#2F0E6D'/>
                        </div> {/*} color='#C100F5' {*/}
                    </div>
                </div>

                <div className='col-lg-6 col-md-6 col-sm-12 align-self-center // pt-3 pt-lg-0'>
                    <img src={myPic} className='w-100 h-lg-75 h-md-100 h-sm-100 rounded-4' alt="#" />
                    <Carousel />
                </div>

            </div>
            <div className='row m-3'>
                <div className="row col-12 // text-white">
                    <p>
                        I began a family business in the food industry. 
                        I am responsible for establishing and managing the restaurant's systems, such as supply chain management, POS systems, dashboard creation for menu items, and food cost calculations. 
                        In addition, I have a personal interest in stock trading and forex, which led me to study and explore algorithmic trading strategies. 
                        I also studied various coding techniques, which has further increased my interest in the IT field.
                        I entered Australia with a Working Holiday visa and have worked in various fields. While working, I continued to expand my knowledge in IT and various programming languages.
                        I am pursuing a Master's degree in Information Technology at Flinders University to further enhance my knowledge and gain practical experience in various IT-related areas.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Bio;
import React from "react";

// Css
import './css/AllAward.css';


const Certificate=(props)=> {
    return(
        <div className='cer-hover card // m-2 rounded-4' style={{ backgroundColor: 'rgba(230, 230, 230, 1)'}}>
            <a href={props.href} download={props.file}><img className='rounded-4' variant="top" src={props.img} style={{ height: '100%', width: '100%' }} alt='#' /></a>
            <h6 className='card-title d-flex align-items-center justify-content-center'>{props.name}</h6>
        </div>
    )
}

export default Certificate;
import React, { useState, useEffect } from "react";
import ExpContainer from './ExpContainer';

// CSS
import './css/Exp.css';

// Images
import Heng_Charoen from './images/heng_charoen.png';
import Apple from './images/apple.jpg';
import Irrigation from './images/irrigation.jpg';
import Fifo_1 from './images/fifo_accom.jpg';
import Fifo_2 from './images/fifo_canteen.jpg';
import Blackhole from './images/blackhole.gif';


const ExpComponent=()=> {
    //===================================================================== 
    // Function Detecting Mouse Movement And Move Follow the Mouse Cursor |
    //===================================================================== 
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [position_I, setPosition_I] = useState({ x: 0, y: 0 });
    const [position_II, setPosition_II] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const updatePosition = (event) => {
            // Check if the clients have changed the pages which means the object center is no longer exist or equal to null
            if(document.querySelector(".center")==null) return;

            // Get position for the center, parent point
            const parent = document.querySelector(".center");
            const { top, left } = parent.getBoundingClientRect();

            //--- Set position X axis
            let hoizontal = left-15; //Radius = 140
            if(event.clientX >= left-30 && event.clientX <= left){
                hoizontal = event.clientX;
            }
            else if(event.clientX > left){
                hoizontal = left;
            }
            else if(event.clientX < left-30){
                hoizontal = left-30;
            }
            
            //Set position Y axis
            let vertical = top-80;
            if(event.clientY >= top-95 && event.clientY <= top-65){
                vertical = event.clientY;
            }
            else if(event.clientY < top-95){
                vertical = top-95;
            }
            else if(event.clientY > top-65){
                vertical = top-65;
            }
            //======================================================

            //--- Set position X_I axis
            let hoizontal_I = left-30;
            if(event.clientX >= left-53 && event.clientX <= left-6){
                hoizontal_I = event.clientX;
            }
            else if(event.clientX > left-6){
                hoizontal_I = left-6;
            }
            else if(event.clientX < left-53){
                hoizontal_I = left-53;
            }

            // Set position Y_I axis
            let vertical_I = top-95;
            if(event.clientY >= top-120 && event.clientY <= top-70){
                vertical_I = event.clientY;
            }
            else if(event.clientY < top-120){
                vertical_I = top-120;
            }
            else if(event.clientY > top-70){
                vertical_I = top-70;
            }
            //======================================================

            //--- Set position X_I axis
            let hoizontal_II = left-45;
            if(event.clientX >= left-75 && event.clientX <= left-12){
                hoizontal_II = event.clientX;
            }
            else if(event.clientX > left-12){
                hoizontal_II = left-12;
            }
            else if(event.clientX < left-75){
                hoizontal_II = left-75;
            }

            // Set position Y_I axis
            let vertical_II = top-110;
            if(event.clientY >= top-142 && event.clientY <= top-78){
                vertical_II = event.clientY;
            }
            else if(event.clientY < top-142){
                vertical_II = top-142;
            }
            else if(event.clientY > top-78){
                vertical_II = top-78;
            }
            //======================================================

            // Check for center
            if((event.clientX >= left+15 && event.clientX <= left+125 && event.clientY >= top+15 && event.clientY <= top+125)){ //  || (event.clientX >= left+40 && event.clientX <= left+100 && event.clientY >= top && event.clientY <= top+140) || (event.clientX >= left && event.clientX <= left+140 && event.clientY >= top+40 && event.clientY <= top+100)      
                hoizontal = left-15;
                vertical = top-80;
                hoizontal_I = left-30;
                vertical_I = top-95;
                hoizontal_II = left-45;
                vertical_II = top-110;
            }
            //======================================================
            
            // Move object to the allocated position
            setPosition({
                x: hoizontal,
                y: vertical
            });
            setPosition_I({
                x: hoizontal_I,
                y: vertical_I 
            });
            setPosition_II({
                x: hoizontal_II,
                y: vertical_II 
            });

        };
        
        const cursor = document.addEventListener("mousemove", updatePosition);
        return (cursor);
    }, []);
    //=====================================================================

    return(
        <div className='position-relative // rounded-4 m-3 p-3' style={{backgroundColor: 'rgba(32,32,32, 0.8)' }}>
            {/*Beautify Object*/}
            <img className='center rounded-circle position-absolute top-50 start-50 translate-middle' src={Blackhole} 
            style={{width:'140px', height:'140px'}} alt='#' />
            
            <div className='first-4col border-5 position-absolute rounded-circle' 
            style={{height:'140px', width:'140px', left: position.x, top: position.y}}/>
            
            <div className='second-4col border-5 position-absolute rounded-circle' 
            style={{height:'170px', width:'170px', left: position_I.x, top: position_I.y}} />

            <div className='third-4col border-5 position-absolute rounded-circle' 
            style={{height:'200px', width:'200px', left: position_II.x, top: position_II.y}} />
            {/*Beautify Object*/}
            
            <div className='position-relative d-flex align-items-center justify-content-center text-warning'>
                <h2 className='text-head'>WORKING EXPERIENCE</h2>
            </div>

            <ExpContainer name='2019' id='exp_1' 
            img={Heng_Charoen}
            href="https://www.instagram.com/hengcharoean_butchery/"
            text={["General Manager", 
            <br/>, "Highway Steak, Heng Charoen Butchery Restaurant | Thailand",
            <br/>, <br/>, "Roles: ",
            <br/>, "- CCTV system installation",
            <br/>, "- Install and maintain POS and Payment system",
            <br/>, "- Control restaurant dashboard - created menus, calculated the cost, managed income and expenses accounts",
            <br/>, "- Check for the stocks, contact Suppliers, open orders"  
            ]}/>

            <ExpContainer name='2020' id='exp_2' 
            img={Apple}
            href="https://jeftomson.com.au/"
            text={["Works Related to Working and Holiday Visa", 
            <br/>, "- Farm Worker - Agricultural Labour Hire Australia Pty Ltd",
            <br/>, "- Process Worker - GEOFFREY THOMPSON Pty Ltd",
            <br/>, "- Irrigation worker - KAGOME Australia Pty Ltd",
            <br/>, "- Uber Eat", 
            ]}/>

            <ExpContainer name='2021' id='exp_3'
            img={Irrigation} 
            href="https://kagome.com.au/"
            text={["Works Related to Working and Holiday Visa", 
            <br/>, "- Farm Worker - Agricultural Labour Hire Australia Pty Ltd",
            <br/>, "- Process Worker - GEOFFREY THOMPSON Pty Ltd",
            <br/>, "- Irrigation worker - KAGOME Australia Pty Ltd",
            <br/>, "- Uber Eat", 
            ]}/>

            <ExpContainer name='2022' id='exp_4' 
            img={Fifo_1}
            href="https://www.techforce.com.au/"
            text={["FIFO Utilities", 
            <br/>, "Techforce Staffing Services Pty. Ltd.",
            <br/>, "Working in mine site at Olympic dam | SA Australia",
            <br/>, <br/>, "Roles: ",
            <br/>, "- Admin",
            <br/>, "- Groundkeeper",
            <br/>, "- Housekeeper"  
            ]}/>

            <ExpContainer name='2023' id='exp_5' 
            img={Fifo_2}
            href="https://www.techforce.com.au/"
            text={["FIFO Utilities", 
            <br/>, "Techforce Staffing Services Pty. Ltd.",
            <br/>, "Working in mine site at Olympic dam | SA Australia",
            <br/>, <br/>, "Roles: ",
            <br/>, "- Admin",
            <br/>, "- Groundkeeper",
            <br/>, "- Housekeeper"  
            ]}/>

        </div>
    )
}

export default ExpComponent;
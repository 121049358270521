import React from 'react';

// Components
import Navbar from '../../components/navbar/Navbar';
import Intro from '../../components/introduction/Intro';

// Images
import Background from './images/background.gif'; 


const Home=()=> {
    return(
        <div className='#' style={{ backgroundImage: `url(${Background})` , backgroundSize: 'cover', minHeight: '100vh', maxHeight: '100%' }} >
            <Navbar />
            <Intro />
        </div>
    )
}

export default Home;
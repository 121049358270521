import React from "react"

const Project=(props)=> {
    return(
        <div className='card m-2 rounded-4' style={{ backgroundColor: 'rgba(230, 230, 230, 1)'}}>
            <img className='rounded-4' variant="top" src={props.img} style={{ height: '100%', width: '100%' }} alt='#' />
            <h6 className='card-title d-flex align-items-center justify-content-center'>{props.name}</h6>
            <div class="card card-body" style={{ backgroundColor: 'rgba(230, 230, 230, 1)'}}>{props.text}</div>
        </div>
    )
}

export default Project;
import React from "react";

// CSS
import './css/Education.css';

// Pictures
import school_I from './images/ru.jpg';
import school_II from './images/flinders.jpg';
// Files
import TDech_Transcript from './files/Unofficial_Transcript.pdf';
import TDech_Transcript_TH from './files/Official_Transcript.pdf';


const Education=()=> {

    return(
        <div className='position-relative rounded-4 m-3 mt-4 p-2 pt-3' style={{ backgroundColor: 'rgba(32,32,32, 0.8)' }}>
            <div className='d-flex align-items-center justify-content-center // text-warning'>
                <h2 className='text-head'>EDUCATION</h2>
            </div>

            <div className='row m-3 d-flex align-items-center justify-content-center'>
                <div className='col-lg-8 col-md-6 col-sm-12 rounded-5 text-light // p-3 me-2' style={{ backgroundImage: 'linear-gradient(to top right, rgba(4, 63, 129, 0.85), rgba(42, 13, 62, 0.85))'}}>
                    <p className="fw-bold fst-italic">Bachelor of Business Administration (Completed 2013)</p>
                    <p>Ramkhamhaeng University | Bangkok, Thailand</p>
                    <p><a href={TDech_Transcript_TH} download="Official_Transcript.pdf" className="download btn rounded-pill d-inline-flex align-items-center justify-content-center text-light"><i className="fa-solid fa-download" /> &nbsp;Download Transcript</a> </p>
                </div>
                <div className='col-lg-3 col-md-5 col-sm-6 // m-2'>
                    <a href="http://www.iis.ru.ac.th/" target={'_blank'} rel="noreferrer">
                        <img src={school_I} className='eduSpring h-100 w-100 rounded-circle' alt='#'/> 
                    </a>
                </div>
            </div>  

            <div className='row m-3 d-flex align-items-center justify-content-center'>
                <div className='col-lg-3 col-md-5 col-sm-6 // m-2'>
                    <a href="https://www.flinders.edu.au/" target={'_blank'} rel="noreferrer">
                        <img src={school_II} className='eduSpring h-100 w-100 rounded-circle' alt='#'/>
                    </a>
                </div>
                <div className='col-lg-8 col-md-6 col-sm-12 rounded-5 text-light // p-3 ms-2' style={{ backgroundImage: 'linear-gradient(to top right, rgba(4, 63, 129, 0.85), rgba(42, 13, 62, 0.85))'}}>  
                    <p className="fw-bold fst-italic d-flex justify-content-end">Master of Information Technology (Undertaking 2023 - 2024)</p>
                    <p className="d-flex justify-content-end">Flinders University | Adelaide, SA, Australia </p>
                    <p className="d-flex justify-content-end"><a href={TDech_Transcript} download="Unofficial_Transcript.pdf" className="download btn rounded-pill d-inline-flex align-items-center justify-content-center text-light"><i className="fa-solid fa-download" /> &nbsp;Download Transcript</a> </p>
                </div>
            </div>
             
        </div>
    )
}

export default Education;

// linear-gradient(to bottom left, rgba(0, 46, 77, 0.8), rgba(0, 179, 107, 0.8))
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/home_page/Home';
import About from './pages/about_page/About';
import Portfolio from './pages/portfolio_page/Portfolio';
import Weather from './pages/weather_page/Weather';
import Contact from './pages/contact_page/Contact';

const App=()=> {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/about' element={<About/>} />
        <Route path='/portfolio' element={<Portfolio/>} />
        <Route path='/weather' element={<Weather/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/' element={<Home/>} />
      </Routes>
    </BrowserRouter>

  )
}

export default App;
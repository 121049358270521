import React from "react"

// Css
import './css/Bio.css';

const Reuse_Progress=(props)=> {
    const circleWidth = 100;
    const radius = 40;
    const dashArray = radius * Math.PI * 2;
    const dashOffset = dashArray - (dashArray * props.level) / 100;

    return(
        <div className="#">
            <svg className='#' width={'100'} height={'100'} viewBox={'0 0 100 100'}>
                <defs>
                    <linearGradient id={props.gradientId} x1="0%" y1="0%" x2="75%" y2="100%">
                        <stop offset="0%" stopColor={props.color_I} />
                        <stop offset="100%" stopColor={props.color_II} />
                    </linearGradient>
                </defs>

                <circle className="circle-background" cx={circleWidth / 2} cy={circleWidth / 2} strokeWidth='20px' r={radius} />
                <circle className="circle-progress" cx={circleWidth / 2} cy={circleWidth / 2} strokeWidth='20px' r={radius} 
                style={{ strokeDasharray: dashArray, '--dashOffset': dashOffset, '--ground': props.ground, stroke: `url(#${props.gradientId})` }}           
                    transform={`rotate(-180 ${circleWidth / 2} ${circleWidth / 2})`} /> {/*} '--color': props.color {*/} 
                
                <text className="#" x={circleWidth / 2} y={circleWidth / 2} dominantBaseline="middle" textAnchor="middle" fill="white">
                    {props.text}
                </text>
            </svg>
        </div>
    )
}

export default Reuse_Progress;
import React from 'react';
import Navbar from '../../components/navbar/Navbar';
import FollowMe from '../../components/followMe/FollowMe';

// Components
import Bio from '../../components/biography/Bio';
import Education from '../../components/education/Education';
import Particle from '../../components/particle/Particle';

function About() {
    return(
        <div style={{ backgroundColor: 'rgba(18, 16, 39, 1)', backgroundSize: 'cover', height: '100%'}}>
            <Particle/>
            <Navbar />    
            <Bio />
            <Education />
            <FollowMe />
        </div>
    )
}

export default About;
import React from 'react';

// Css
import './css/Bio.css';

// Images
import Pic_1 from './images/mine_I.jpg';
import Pic_2 from './images/mine_II.jpg';
import Pic_3 from './images/mine_III.jpg';
import Pic_4 from './images/mine_IV.jpg';
import Pic_5 from './images/mine_V.jpg';


const Carousel=()=> {
    return(
        <div id="carouselFade" className="carousel slide carousel-fade rounded-4 // mt-2" data-bs-ride="carousel" style={{ backgroundColor: '#343a40', background: 'rgba(52, 58, 64, 0.5)' }}>
            <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselFade" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselFade" data-bs-slide-to="1" className="active" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselFade" data-bs-slide-to="2" className="active" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselFade" data-bs-slide-to="3" className="active" aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#carouselFade" data-bs-slide-to="4" className="active" aria-label="Slide 5"></button>
            </div>

            <div className="carousel-inner rounded-4">
                <div className="carousel-item active d-flex justify-content-center" data-bs-interval="3500">
                    <img src={Pic_1} className='size-bio-css' alt="#" />
                </div>
                <div className="carousel-item d-flex justify-content-center" data-bs-interval="3500">
                    <img src={Pic_2} className='size-bio-css' alt="#" />
                </div>
                <div className="carousel-item d-flex justify-content-center" data-bs-interval="3500">
                    <img src={Pic_3} className='size-bio-css' alt="#" />
                </div>
                <div className="carousel-item d-flex justify-content-center" data-bs-interval="3500">
                    <img src={Pic_4} className='size-bio-css' alt="#" />
                </div>
                <div className="carousel-item d-flex justify-content-center" data-bs-interval="3500">
                    <img src={Pic_5} className='size-bio-css' alt="#" />
                </div>
            </div>

            <button className="carousel-control-prev" type="button" data-bs-target="#carouselFade" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>

            <button className="carousel-control-next" type="button" data-bs-target="#carouselFade" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>

    )
}

export default Carousel;
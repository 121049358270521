import React from "react";

// CSS
import './css/Intro.css';

// Pics
import myPic from './images/me_pc_1.png';

// Files
import TDech_CV from './files/Official_CV.pdf';


const Intro=()=> {
    return(
        <div>
            <div className='row rounded-4 // m-3 mt-4 mb-2 ms-lg-5 me-lg-5 ms-md-3 me-md-3 ms-sm-1 me-sm-1' style={{ backgroundColor: 'rgba(26,26,26, 0.8)' }}>
                <div className='col-lg-6 col-md-12 col-sm-12 rounded-4 align-self-center text-white // p-3 p-sm-2 p-md-3 p-lg-3 p-xl-4 p-xxl-5' 
                style={{ backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center', }} >
                    <h1 className='name-color'>TANAPONG DECHSAKUL</h1>
                    <h3 className='name-normal'>Call me <span className='name-color'>"KENG"</span></h3>
                    <br/>
                    <br/>
                    <h3 className='name-emphasize'>Crazy Programmer and Algorithmics Trader</h3>
                    <p className='name-text'>
                        Keep going until you see the light at the end of the tunnel.
                    </p>
                </div>

                <div className='col-lg-6 col-md-12 col-sm-12 d-flex align-items-center justify-content-center align-self-center'>
                    <img src={myPic} className='rounded-4' alt="#" style={{height: '62%', width: '62%'}} />
                </div>

                {/*}
                <div className='col-lg-3 col-md-5 col-sm-12 d-flex align-items-center justify-content-center // '>
                    <img className='flow rounded-circle' src={Pic_II} alt='#' style={{height: '90%', width: '90%'}}/>
                </div>
                {*/}

            </div>
            
            <div className='row m-3 ms-5 me-5 mt-4 mt-lg-0 mt-md-3'>
                <div className='intro-hover col-lg-2 col-md-4 col-sm-6'>
                    <a className='text-light' href="https://www.linkedin.com/in/tanapongdech" target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}><h2 className="intro-spring"><i className="fa-brands fa-linkedin"/><h5 className="d-inline"> &nbsp;LinkedIn</h5></h2></a>
                </div>
                <div className='intro-hover col-lg-2 col-md-4 col-sm-6'>
                    <a className='text-light' href="https://www.facebook.com/tanapongdech" target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}><h2 className="intro-spring"><i className="fa-brands fa-facebook"/><h5 className="d-inline"> &nbsp;Facebook</h5></h2></a>
                </div>
                <div className='intro-hover col-lg-2 col-md-4 col-sm-6'>
                    <a className='text-light' href="https://www.instagram.com/tanapongdech/" target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}><h2 className="intro-spring"><i className="fa-brands fa-instagram"/><h5 className="d-inline"> &nbsp;Instagram</h5></h2></a>
                </div>
                <div className='intro-hover col-lg-2 col-md-4 col-sm-6'>
                    <a className='text-light' href="https://github.com/TanapongAUS" target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}><h2 className="intro-spring"><i className="fa-brands fa-github"></i><h5 className="d-inline"> &nbsp;Github</h5></h2></a>
                </div>
            </div>

            <a href={TDech_CV} download="Official_CV.pdf" className="download btn rounded-pill d-inline-flex align-items-center justify-content-center text-light // ms-5 mb-3"><i className="fa-solid fa-download" /> &nbsp;Download CV</a>
            
        </div>
    )
}

export default Intro;